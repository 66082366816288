import React from 'react';
import styled from 'styled-components';

import {
  medium,
  large,
  appear,
  xlarge,
  between,
  staggeredAppear
} from '../../utils/mixins';

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 3% 0;
  font-size: ${between(80, 100, 320, 1024)};
  line-height: 1em;
  font-weight: 600;
  letter-spacing: -0.015em;
  animation: ${appear} 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${staggeredAppear(1)}
  
  @media (min-width: ${medium}) {
    max-width: 98%;
  }

  @media (orientation: landscape) {
    max-width: 98%;

  }

  @media (min-width: ${large}) {
  font-size: ${between(100, 200, 1024, 2000)};

  }

  @media (min-width: ${xlarge}) {
  font-size: 130px;

  }
`;

const TitleComponent = () => {
  return <Wrapper>One Last Dance</Wrapper>;
};

export default TitleComponent;
