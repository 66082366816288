import React from 'react';
import styled from 'styled-components';

import {
  between,
  large,
  xlarge,
  appear,
  staggeredAppear
} from '../../utils/mixins';

// import { createUniqueName } from '../../utils/forms'

const Copy = styled.p`
  margin-bottom: 20px;
  font-size: ${between(20, 31, 320, 1024)};
  line-height: 1em;
  letter-spacing: -0.25px;
  text-transform: uppercase;
  animation: ${appear} 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${staggeredAppear(0)}

  @media (min-width: ${large}) {
  margin-top: 0;

  }

  @media (min-width: ${xlarge}) {
    font-size: 31px;
  }
`;

const Body = () => {
  return (
    <Copy>
      Enno, Jonny &amp; Ollie
      <br />
      invite you to join us for
    </Copy>
  );
};

export default Body;
