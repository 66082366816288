import React from 'react';
import styled from 'styled-components';

import { between, xlarge, appear, staggeredAppear } from '../../utils/mixins';

// import { createUniqueName } from '../../utils/forms'

const Copy = styled.p`
  font-size: ${between(16, 20, 320, 1024)};
  line-height: 1.25em;
  text-transform: uppercase;
  animation: ${appear} 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${staggeredAppear(2)}
  @media (min-width: ${xlarge}) {
    font-size: 20px;
  }
`;

const Details = ({ name, label, required, theme, type }) => {
  return (
    <Copy theme={theme}>
      at Miniclub – Cookies
      <br />
      Music by Nico Stojan &amp; DJ Schowi
      <br />
      Sat, 14 Dec 2019, 10:30 pm
      <br />
      Entrance is at{' '}
      <a
        href="https://goo.gl/maps/wnwGguuvgVPy6KC2A"
        target="_blank"
        rel="noopener noreferrer"
      >
        Behrenstraße 55
      </a>
      <br />
      <br />
      RSVP for Guestlist by Tue, 10 Dec
    </Copy>
  );
};

export default Details;
