import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
// import Select from "react-select";

import { Input } from '../Input';
import { Select } from '../Select';
import {
  primary,
  secondary,
  medium,
  large,
  error,
  appear,
  staggeredAppear
} from '../../utils/mixins';
import { pushToBackend } from '../../utils/database';

const Wrapper = styled.section`
  display: flex;
  min-height: 287px;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 10% auto 0;
  animation: ${appear} 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${staggeredAppear(3)}
  & > * {
    flex: 1;
  }
  @media (min-width: ${medium}) {
    min-height: 144px;
    flex-direction: row;
    & > * {
      flex-basis: 50%;
    }
  }
  @media (min-width: ${large}) {
    min-height: 72px;
    margin-top: 80px;
    & > * {
      flex: 1;
    }
  }
`;

const ButtonWrap = styled.div`
  flex-basis: 50%;
  padding-right: 16px;
  @media (min-width: ${large}) {
    flex: 0 1;
  }
`;

const Button = styled.button`
  height: 56px;
  width: 100%;
  margin: 0 8px 16px;
  padding: 0 16px;
  background: ${primary};
  font: inherit;
  font-size: 25px;
  color: #000;
  text-transform: inherit;
  border: 4px solid ${primary};

  &:hover {
    border-color: ${secondary};
    background: ${secondary};
    cursor: pointer;
  }

  &:focus {
    border-color: ${secondary};
    box-shadow: none;
    outline: none;
  }
`;
const Message = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 11px 8px;
  min-height: 56px;
  margin: 0 8px 16px;
  border: 4px solid ${primary};
  align-items: center;
  justify-content: center;
  @media (min-width: ${large}) {
    padding: 11px 16px;
  }

  &.error {
    border-color: ${error};
    color: ${error};
  }
`;
function Form() {
  const [formValues, setFormValues] = useState({});
  const [formFeedback, setFormFeedback] = useState();
  // update state every time the input changes
  const handleInputChange = useCallback(
    event => {
      const { value, name } = event.target;
      setFormValues({ ...formValues, [name]: value });
    },
    [formValues]
  );
  // submit form to backend
  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      pushToBackend(formValues)
        .then(() => {
          setFormFeedback(<Message>See you at the party</Message>);
        })
        .catch(() => {
          setFormFeedback(
            <Message className="error">
              Damn, something went wrong so please RSVP by email at{' '}
              <a href="mailto:onelastdance@hyam.de">onelastdance@hyam.de</a>
            </Message>
          );
        });
    },
    [formValues]
  );
  const name = {
    name: 'name',
    type: 'text',
    required: true,
    placeholder: 'Name',
    value: formValues.name || ''
  };
  const email = {
    name: 'email',
    type: 'email',
    required: true,
    placeholder: 'Email',
    value: formValues.email || ''
  };
  const rsvp = {
    name: 'rsvp',
    placeholder: 'Attendance',
    required: true,
    options: [
      {
        value: 'true',
        label: 'Yes, I am in'
      },
      { value: 'false', label: 'No, I can’t dance' }
    ],
    value: formValues.rsvp || ''
  };
  // get the email from url in case it is passed as a query string
  useEffect(() => {
    const qs = queryString.parse(window.location.search);
    setFormValues({ ...formValues, email: qs.email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log({ formValues });
  return (
    <form onSubmit={handleSubmit}>
      <Wrapper>
        {/* {!formFeedback ? (
          <>
            <Input {...name} onChange={handleInputChange} />
            <Input {...email} onChange={handleInputChange} />
            <Select {...rsvp} onChange={handleInputChange} />
            <ButtonWrap>
              <Button type="submit">Send</Button>
            </ButtonWrap>
          </>
        ) : (
          formFeedback
        )} */}
        <Message>Guestlist closed. See you at the party</Message>
      </Wrapper>
    </form>
  );
}

export default Form;
