import React, { useEffect, useState } from 'react';

import './App.scss';
import Form from './components/Form';
import { Title } from './components/Title';
import { Body } from './components/Body';
import { Details } from './components/Details';

function App() {
  const [reveal, triggerReveal] = useState(false);
  // give the webgl some time to render before rendering the app
  useEffect(() => {
    setTimeout(() => {
      triggerReveal(true);
    }, 100);
  }, []);
  return reveal ? (
    <div className="app">
      <div className="app-wrap">
        <div className="app-logo-wrap">
          <Body />
        </div>
        <div className="app-wrap-title">
          <Title />
        </div>
        <div className="app-wrap-details">
          <Details />
          <Form />
        </div>
      </div>
    </div>
  ) : null;
}

export default App;
