import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { primary, secondary } from '../../utils/mixins';

const Fieldset = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;

  input {
    appearance: none;
    width: 100%;
    height: 56px;
    text-transform: uppercase;
    padding: 0 16px;
    border: 4px solid ${primary};
    border-radius: 0;
    background: transparent;
    color: ${primary};
    outline: none;
    font: inherit;
    font-size: inherit;

    &:hover,
    &:focus {
      border-color: ${secondary};
    }
  }

  label {
    display: block;
    margin-bottom: 16px;
    margin-left: 8px;
    margin-right: 8px;

    span {
      display: block;
      margin-bottom: 8px;
    }
  }
`;

const Input = ({
  name,

  required,
  theme,
  type,
  value,
  placeholder,
  onChange
}) => {
  const id = name;

  return (
    <Fieldset theme={theme}>
      <label htmlFor={id}>
        {/* dangeously setting content allows stringified html to be passed into the label. this can be used to place a link inside the label */}
        {/* <span dangerouslySetInnerHTML={{ __html: label }} /> */}
        <input
          {...{ id, name, type, required, value, placeholder, onChange }}
        />
      </label>
    </Fieldset>
  );
};

export const inputPropTypes = {
  name: PropTypes.string.isRequired,

  theme: PropTypes.oneOf(['orange', 'purple']),
  required: PropTypes.bool
};

Input.propTypes = inputPropTypes;

Input.defaultProps = {
  required: false,
  theme: 'purple'
};

export default Input;
